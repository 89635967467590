import AOS from "aos"
import "aos/dist/aos.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "font-awesome/fonts/fontawesome-webfont.ttf"
import Carousel from "nuka-carousel"
import React, { useEffect, useState } from "react"
import "../assets/css/style.css"
import api from "../api"

import Bigcommerce_logo from "../assets/images/Bigcommerce_logo.png"
import F1_decrement from "../assets/images/F1_decrement.png"
import F1_Image_01 from "../assets/images/F1_Image_01.png"
import F1_increment from "../assets/images/F1_increment.png"
import F1_Messenger_icon from "../assets/images/F1_Messenger_icon.png"
import F2_image_01 from "../assets/images/F2_image_01.png"
import F3_bg_mobile_frame from "../assets/images/F3_bg_mobile_frame.png"
import F3_deal_bg from "../assets/images/F3_deal_bg.png"
import F3_grapg_bg from "../assets/images/F3_grapg_bg.png"
import F4_chat_now_button from "../assets/images/F4_chat_now_button.png"
import F4_image_01 from "../assets/images/F4_image_01.png"
import F5_bg_01 from "../assets/images/F5_bg_01.png"
import F5_bg_02 from "../assets/images/F5_bg_02.png"
import F6_image_01 from "../assets/images/F6_image_01.png"
import Icon_active_user_blue from "../assets/images/Icon_active_user_blue.png"
import Icon_click_through_blue from "../assets/images/Icon_click_through_blue.png"
import Icon_open_rate_white from "../assets/images/Icon_open_rate_white.png"
import Icon_spam_free_blue from "../assets/images/Icon_spam_free_blue.png"
import Ksum_logo_blue from "../assets/images/Ksum_logo_blue.png"
import Left_upward_arrow from "../assets/images/Left_upward_arrow.png"
import Mail_icon_in_the_txt_field from "../assets/images/Mail_icon_in_the_txt_field.png"
import Messenger_icon_small_reverse from "../assets/images/Messenger_icon_small_reverse.png"
import MSME_logo_blue from "../assets/images/MSME_logo_blue.png"
import Right_arrow_white_in_the_txt_field from "../assets/images/Right_arrow_white_in_the_txt_field.png"
import Right_upward_arrow from "../assets/images/Right_upward_arrow.png"
import Screen_inside_mob_01_on_landing from "../assets/images/Screen_inside_mob_01_on_landing.png"
import Screen_inside_mob_02_on_landing from "../assets/images/Screen_inside_mob_02_on_landing.png"
import Screen_inside_mob_03_on_landing from "../assets/images/Screen_inside_mob_03_on_landing.png"
import Shoppify_logo from "../assets/images/Shoppify_logo.png"
import Startup_india_logo_blue from "../assets/images/Startup_india_logo_blue.png"
import Woocommerce_logo from "../assets/images/Woocommerce_logo.png"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [bEmail,setBEmail]=useState("");

  // Aos
  useEffect(() => {
    AOS.init()
  }, [])

  const handleLeads=async (position,emailId) => {
    try {
      const response=await api.collectLeads({
        location: position,
        email: emailId.trim(),
      })
      toast.success('Lead Collected',{
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (error) {
      toast.error(error.message,{
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  return (
    <Layout>
      <SEO />
      <ToastContainer />
      <header className="pb-3">
        <div className="container d-flex justify-content-between text-light wrapper">
          <div className="headerText pt-5 text-md-left text-center">
            <h1>Maximize your ecommerce sales with ecart.chat</h1>
            <p>
              Ecart.chat enables facebook messenger chat bot for your ecommerce
              store to reduce open carts and regain sales by redirecting
              customers
            </p>
            <p>
              <b>Want to know more?</b>
            </p>
            <div className="input-group mb-3 mx-auto position-relative">
              <form
                onSubmit={e => e.preventDefault()}
                style={{ display: "flex" }}
              >
                <input
                  type="text"
                  onChange={e => setEmail(e.target.value)}
                  className="form-control"
                  value={email}
                  placeholder="Enter your mail id"
                  aria-label="Recipient's email"
                  aria-describedby="button-addon2"
                />
                <img
                  className="position-absolute mailIcon"
                  src={Mail_icon_in_the_txt_field}
                />
                <div className="input-group-append">
                  <button
                    onClick={()=>handleLeads('top',email)}
                    className="btn btn-outline-secondary orangeBG"
                    type="submit"
                    id="button-addon2"
                  >
                    <img src={Right_arrow_white_in_the_txt_field} />
                  </button>
                </div>
              </form>
              <button
                type="button"
                className="btn btn-outline-primary d-inline text-light ml-sm-3 mx-auto mt-3 mt-sm-0"
              >
                <img
                  className="messIcon"
                  src={F1_Messenger_icon}
                  width="15px"
                />
                &nbsp;Subscribe
              </button>
            </div>
          </div>
          <div className="mt-3 mt-sm-0">
            <Carousel
              autoplay
              withoutControls
              heightMode="max"
              initialSlideWidth={260}
            >
              <div className="slider-item">
                <img src={Screen_inside_mob_01_on_landing} width="260px" />
              </div>
              <div className="slider-item">
                <img src={Screen_inside_mob_02_on_landing} width="260px" />
              </div>
              <div className="slider-item">
                <img src={Screen_inside_mob_03_on_landing} width="260px" />
              </div>
            </Carousel>
          </div>
        </div>
      </header>
      <div className="container text-center p-3 p-sm-5 blueText">
        <h3 className="font-weight-normal">
          Messenger Marketing for Higher Sales!
        </h3>
        <p className="mb-0">
          How about 88% Open Rates and 56% CTRs? Over 2 BILLION messages get
          sent on FB every month!
        </p>
      </div>
      <div className="fbBenifitsContainer wrapper mb-5 d-flex container justify-content-center justify-content-md-between flex-lg-wrap">
        <div
          data-aos="fade-up"
          data-aos-duration={500}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset={10}
          className="fbBenifits m-3 orangeBG text-center p-4 text-light"
        >
          <img src={Icon_open_rate_white} />
          <h2 className="font-weight-light mt-1 mb-0">20%</h2>
          <p className="mb-0">Click through</p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration={500}
          data-aos-delay={300}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset={10}
          className="fbBenifits m-3 text-center p-4 blueText"
        >
          <img src={Icon_click_through_blue} />
          <h2 className="font-weight-light mt-1 mb-0">80%</h2>
          <p className="mb-0">Open rate</p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration={500}
          data-aos-delay={600}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset={10}
          className="fbBenifits m-3 text-center p-4 blueText"
        >
          <img src={Icon_active_user_blue} />
          <h2 className="font-weight-light mt-1 mb-0">1.6 B</h2>
          <p className="mb-0">Active users</p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration={500}
          data-aos-delay={900}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset={10}
          className="fbBenifits m-3 text-center p-4 blueText"
        >
          <img src={Icon_spam_free_blue} />
          <h2 className="font-weight-light mt-1 mb-0">100%</h2>
          <p className="mb-0">Spam free</p>
        </div>
      </div>
      <h2 className="text-center font-weight-normal container py-3 blueText">
        What make us different?
      </h2>
      <div className="featureSection container mb-5">
        <div className="d-flex w-100 pt-4 mb-4 f1 justify-content-md-between wrapper">
          <div
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor=".f1"
            data-aos-anchor-placement="center-bottom"
            className="featureText py-md-3 px-md-0 p-sm-5 p-3 position-relative"
          >
            <p className="featureNumbers">1</p>
            <h5 className="kindabold">Capture your Messenger leads</h5>
            <p>
              Capture your customer Messenger leads. With tree method like
              discount popup and updates popup and add to cart checkbox
            </p>
            <div className="absolutely">
              <a href>
                <img className="messIcon" src={F1_Messenger_icon} />
              </a>
              <a href>
                <i className="text-muted">
                  Try receiving updates,
                  <br />
                  directly from messenger
                </i>
              </a>
              <img src={Right_upward_arrow} />
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor=".f1"
            data-aos-anchor-placement="center-bottom"
            className="ctaBox"
          >
            <div className="d-flex">
              <img src={F1_Image_01} width="140px" height="140px" />
              <div className="productDetails ml-sm-3 ml-2">
                <p className="text-muted mb-1">
                  Mesh panel lace-up Front sneakers
                </p>
                <h5>₹1,323</h5>
                <div className="d-flex">
                  <div className="w-50 small">
                    <p className="m-0">Color</p>
                    <p className="m-0">Material</p>
                  </div>
                  <div className="small">
                    <p className="m-0">: Yellow</p>
                    <p className="m-0">: Rubber</p>
                  </div>
                </div>
                <input type="checkbox" id="sendToMessenger" />
                <div>
                  <div />
                </div>
                <label className="text-muted" htmlFor="sendToMessenger">
                  Send to
                  <span className="text-dark">
                    &nbsp;
                    <img src={F1_Messenger_icon} width="13px" />
                    &nbsp; Messenger
                  </span>
                </label>
              </div>
            </div>
            <div className="buynowAddtocart mt-3">
              <p className="small">
                Quantity &nbsp;
                <span className="d-sm-inline d-none">&nbsp; &nbsp;</span>
                <a className="text-decoration-none" href>
                  <img src={F1_decrement} width="18px" />
                </a>
                &nbsp; <span className="d-sm-inline d-none">&nbsp; </span>
                <span>01</span>&nbsp;
                <a className="text-decoration-none" href>
                  <img src={F1_increment} width="18px" />
                </a>
                <span className="d-sm-inline d-none">&nbsp; </span>
              </p>
              <button className="btn btn-dark ml-2 pageButton" disabled>
                Buy Now
              </button>
              <button className="btn btn-light ml-2 pageButton" disabled>
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 pt-4 mb-4 f2 justify-content-md-between wrapper wrapperrev">
          <div
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor=".f2"
            data-aos-anchor-placement="center-bottom"
          >
            <div className="firstMsgBox d-inline-block">
              <p className="small text-truncate mb-1 kindabold">
                Ditsy Print SMock Cami something something
              </p>
              <img src={F2_image_01} width="100%" />
              <button
                className="btn btn-light w-100 mt-2 btn-sm py-2 kindabold"
                disabled
              >
                Check out now
              </button>
            </div>
            <div
              data-aos="fade-right"
              data-aos-duration={500}
              data-aos-anchor=".f2"
              data-aos-anchor-placement="center-bottom"
              data-aos-delay={50}
              className="secondMsgBox d-inline-block ml-2"
            >
              <div className="w-100">
                <p className="small kindabold mb-0">
                  Hey, Tony! You've one item in cart to checkout
                </p>
              </div>
              <div className="w-100 mt-2">
                <p className="small kindabold mb-0">
                  Tie Neck Lace Panel Dress
                </p>
                <img src={F2_image_01} width="100%" />
                <button
                  className="btn btn-primary w-100 mt-2 btn-sm py-2 kindabold"
                  disabled
                >
                  Try it right now
                </button>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor=".f2"
            data-aos-anchor-placement="center-bottom"
            className="featureText position-relative pt-md-4 px-md-0 p-sm-5 p-3"
          >
            <p className="featureNumbers">2</p>
            <h5 className="kindabold">Retarget your abandoned carts</h5>
            <p>
              Send abandoned cart remainder to customer. Retarget abandoned
              customers and decrease cart abandoned rate and increase revenue.
            </p>
            <div className="absolutely">
              <img src={Left_upward_arrow} width="65px" />
              <i className="text-muted">Get abandoned cart messages</i>
              <button className="btn btn-primary font-weight-bold">
                <img
                  className="position-relative align-text-bottom"
                  src={Messenger_icon_small_reverse}
                  width="15px"
                />
                &nbsp; Receive updates
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 f3 pt-4 mb-4 position-relative wrapper">
          <div
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor=".f3"
            data-aos-anchor-placement="center-bottom"
            className="featureText pt-md-5 mt-md-4 mr-lg-5 px-md-0 p-sm-5 p-3"
          >
            <p className="featureNumbers">3</p>
            <h5 className="kindabold">
              Increase your revenue by Messenger campaigns
            </h5>
            <p>
              Educate your customer with sent useful article and make them your
              readers. Send the offer and promotions directly to customers.
            </p>
          </div>
          <div className="absolutely position-relative">
            <img
              data-aos="fade-right"
              data-aos-duration={500}
              data-aos-anchor=".f3"
              data-aos-anchor-placement="center-bottom"
              src={F3_bg_mobile_frame}
              width="230px"
            />
            <img
              data-aos="fade-left"
              data-aos-duration={500}
              data-aos-anchor=".f3"
              data-aos-anchor-placement="center-bottom"
              src={F3_deal_bg}
              width="190px"
            />
            <img
              data-aos="fade-left"
              data-aos-duration={500}
              data-aos-anchor=".f3"
              data-aos-anchor-placement="center-bottom"
              src={F3_grapg_bg}
              width={220}
            />
          </div>
        </div>
        <div className="d-flex w-100 pt-4 mb-4 f4 justify-content-md-between wrapper wrapperrev position-relative">
          <div
            className="firstBox d-flex"
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor=".f4"
            data-aos-anchor-placement="center-bottom"
          >
            <img src={F4_image_01} width="180px" />
            <div className="pl-3 pt-1">
              <p className="kindabold mb-1 mb-sm-0">Tassel round straw bag</p>
              <p className="small mb-1">SKU:bag0103203131</p>
              <h5>₹ 1,232</h5>
              <a className="text-decoration-none" href="#/">
                <img
                  className="mb-1 mb-sm-2"
                  src={F4_chat_now_button}
                  width="80px"
                />{" "}
              </a>
              <br />
              <a className="text-decoration-none" href="#/">
                <img src={F1_decrement} width="18px" />
              </a>
              &nbsp; &nbsp; <span className="small">01</span>&nbsp; &nbsp;
              <a className="text-decoration-none" href="#/">
                <img src={F1_increment} width="18px" />
              </a>
              <br />
              <button className="btn btn-dark pageButton mt-2" disabled>
                Add to cart
              </button>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor=".f4"
            data-aos-anchor-placement="center-bottom"
            className="featureText ml-md-5 p-md-0 pt-md-1 p-sm-5 p-3"
          >
            <p className="featureNumbers">4</p>
            <h5 className="kindabold">Automatic product specific chats</h5>
            <p>
              Shoppers can chat with product specific. Customers can start chat
              with specific product.
            </p>
            <div className="absolutely position-relative">
              <a href>
                <img
                  className="messIcon"
                  src={F1_Messenger_icon}
                  width="20px"
                />
                <i className="text-muted">
                  Click here for
                  <br />
                  initiating the chat
                </i>
              </a>
              <img src={Left_upward_arrow} width="65px" />
            </div>
          </div>
        </div>
        <div className="d-flex w-100 pt-4 mb-4 f5 wrapper justify-content-md-between">
          <div
            className="featureText pb-md- px-md-0 p-sm-5 p-3 mr-lg-5 mr-md-4"
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor=".f5"
            data-aos-anchor-placement="center-bottom"
          >
            <p className="featureNumbers">5</p>
            <h5 className="kindabold">
              Increase your traction by email retargeting
            </h5>
            <p>
              Educate your customer with sent useful article and make them your
              readers. Send the offer and promotions directly to customers.
            </p>
            <div className="position-relative getstarted py-3">
              <label className="position-absolute">Your email id</label>
              <input
                className="mr-2 mb-2"
                type="email"
                name
                placeholder="Eg:riyan1995@gmail.com"
              />
              <button className="btn btn-primary">Get started</button>
            </div>
          </div>
          <div className="position-relative pl-md-3">
            <img
              data-aos="fade-right"
              data-aos-duration={500}
              data-aos-anchor=".f5"
              data-aos-anchor-placement="center-bottom"
              src={F5_bg_01}
              width="245px"
            />
            <img
              data-aos="fade-right"
              data-aos-duration={500}
              data-aos-anchor=".f5"
              data-aos-anchor-placement="center-bottom"
              data-aos-delay={300}
              src={F5_bg_02}
              width="245px"
            />
          </div>
        </div>
        <div className="d-flex w-100 f6 align-items-start justify-content-md-between wrapper wrapperrev position-relative">
          <div
            className="firstBox position-relative"
            data-aos-duration={500}
            data-aos-anchor=".f6"
            data-aos-anchor-placement="center-bottom"
          >
            <p className="kindabold mb-2">Flower Design Stud Earring 1 pair</p>
            <div className="d-flex">
              <img src={F6_image_01} width="140px" height="147px" />
              <div className="ml-sm-3 ml-2">
                <p className="mb-0 kindabold">₹139</p>
                <p className="small kindabold mb-1">Wish to buy later?</p>
                <button className="btn btn-primary btn-sm remindMe orangeBG mb-1">
                  Remind me
                </button>
                <br />
                <div className="position-relative getstarted d-none">
                  <input
                    className="mb-1"
                    type="email"
                    name
                    placeholder="Eg:riyan1995@gmail.com"
                  />
                  <button className="btn btn-primary orangeBG">Receive</button>
                </div>
                <div className="position-relative d-none successfulMsg">
                  <p className="mb-0 blueText">Reminder successfully placed!</p>
                </div>
                <a className="text-decoration-none" href>
                  <img src={F1_decrement} width="18px" />
                  &nbsp; &nbsp;{" "}
                </a>
                <span className="small">01</span>
                <a className="text-decoration-none" href>
                  &nbsp; &nbsp; <img src={F1_increment} width="18px" />
                </a>
                <br />
                <button className="btn btn-light pageButton mt-1" disabled>
                  Add to cart
                </button>
              </div>
            </div>
          </div>
          <div
            className="position-relative featureText pl-md-0 py-md-1 p-sm-5 p-3 mr-lg-5 mr-0 ml-lg-0"
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor=".f6"
            data-aos-anchor-placement="center-bottom"
          >
            <p className="featureNumbers">6</p>
            <h5 className="kindabold">Get reminders for easy checkout</h5>
            <p>
              Shoppers can chat with product specific. Customers can start chat
              with specific product.
            </p>
            <div className="absolutely position-relative">
              <img src={Left_upward_arrow} />
              <a href>
                <img className="messIcon" src={F1_Messenger_icon} />
                <i className="text-muted">
                  Try reminder for
                  <br />
                  getting notifications.
                </i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="recognitionsSection">
        <div className="w-100 text-center py-5 recognitions container blueText">
          <h2 className="font-weight-normal">Recognitions and approvals</h2>
          <p className="mx-auto">
            Educate your customer with sent useful article and make them your
            readers. Send the offer and promotions directly to customers.
          </p>
          <div className="d-flex pt-1 justify-content-between wrapper align-items-baseline mx-auto">
            <img
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-anchor-placement="bottom-bottom"
              className="py-4"
              src={Ksum_logo_blue}
            />
            <img
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-anchor-placement="bottom-bottom"
              data-aos-delay={150}
              className="py-4"
              src={Startup_india_logo_blue}
            />
            <img
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-anchor-placement="bottom-bottom"
              data-aos-delay={300}
              className="py-4"
              src={MSME_logo_blue}
            />
          </div>
        </div>
      </section>
      <div id="integration" className="my-5 mx-auto text-center blueText">
        <h2 className="font-weight-normal">We support</h2>
        <div className="d-flex py-4 justify-content-between wesupport align-items-center mx-auto wrapper">
          <img
            data-aos="fade-up"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            className="pt-md-2 py-md-0 py-3"
            src={Woocommerce_logo}
            width="153px"
          />
          <img
            data-aos="fade-up"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay={150}
            className="pb-md-1 py-md-0 py-3"
            src={Shoppify_logo}
            width="115px"
          />
          <img
            data-aos="fade-up"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay={300}
            className="pb-md-2 py-md-0 py-3"
            src={Bigcommerce_logo}
            width="170px"
          />
        </div>
        <h5 className="font-weight-normal py-3">
          Looking for custom integration?
        </h5>
        <form onSubmit={(e)=>e.preventDefault()}>
        <div className="position-relative getstarted py-3">
          <label
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            className="position-absolute"
          >
            Your email id
          </label>
          <input
            data-aos="fade-left"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            name
            value={bEmail}
            onChange={(e)=>setBEmail(e.target.value)}
            placeholder="Eg:riyan1995@gmail.com"
          />
          <button
            data-aos="fade-right"
            data-aos-duration={500}
            data-aos-anchor-placement="bottom-bottom"
            className="btn btn-primary orangeBG ml-2"
            onClick={()=>handleLeads('bottom',bEmail)}
          >
            Contact Me
          </button>
        </div>
        </form>
      </div>
    </Layout>
  )
}

// 3. Add the plugin to the component
export default IndexPage
